/**
 * MasterOfPoppits - A script for managing and triggering poppits at specified intervals or on events.
 * @param {Object} options - Configuration options.
 * @param {Number} options.poppitInterval - The interval in seconds between poppits.
 * @param {Array} options.triggerEvents - The events that trigger a poppit.
 * @param {Array} options.excludedSelectors - The selectors that should not trigger a poppit.
 * @param {String} options.poppitType - The type of poppit to open ('under' or 'up').
 * @param {String} options.method - The method of poppit opening ('auto' or 'manual').
 * @param {Array} options.poppitUrls - The URLs to open in poppits.
 * @param {Boolean} options.disableChrome - Whether to disable the script on Chrome browsers.
 * @param {Function} options.onSuccess - A custom method to call on poppit success.
 * @param {Function} options.onFailure - A custom method to call on poppit failure.
 * @returns {Object} - The MasterOfPoppits instance.
 *
 * @example
 * const MasterOfPoppits = require('master-of-poppits');
 *
 * const masterOfPoppits = new MasterOfPoppits({
 *   // ... Configuration options (e.g., poppitInterval, triggerEvents, etc.) ...
 * });
 *
 * // If using the 'auto' method, the poppits will be triggered automatically
 * // If using the 'manual' method, the poppits will be triggered by calling the pull method
 * masterOfPoppits.pull();
 */
export class MasterOfPoppits
{
	constructor(options)
	{
		// Default configuration options
		this.DEFAULT_OPTIONS = {
			initialDelay: 5,
			poppitInterval: 60,
			triggerEvents: ['click', 'touchstart'],
			excludedSelectors: ['.exclude-poppit'],
			poppitType: 'under',
			method: 'auto',
			poppitUrls: [],
			disableChrome: false,
			onSuccess: null,
			onFailure: null,
		};

		// Merge user-provided options with default options
		this.options = {...this.DEFAULT_OPTIONS, ...options};

		// Check if the script should be disabled on Chrome browsers
		if (this.options.disableChrome && this.isChromeBrowser()) {
			return;
		}

		// Initialize the script
		this.init();
	}

	/**
	 * Initialize the MasterOfPoppits instance.
	 */
	init()
	{
		// Initialize instance variables
		this.lastPoppitTime = parseInt(localStorage.getItem('lastPoppitTime')) || 0;
		this.firstEventTriggered = localStorage.getItem('firstEventTriggered') === 'true' || false;
		this.isPoppitEventTriggered = false;
		this.useCookies = !this.isLocalStorageSupported();
		this.shuffledUrls = this.shuffleArray(this.options.poppitUrls);
		this.debounceTime = 1000;

		// Attach event listeners if using the 'auto' method
		// Set initial delay
		setTimeout(() => {
			// Attach event listeners if using the 'auto' method
			if (this.options.method === 'auto') {
				this.attachEventListeners();
			} else {
				if (this.options.method === 'manual') {
					// Bind the 'pull' method to the instance
					this.pull = this.pull.bind(this);
				}
			}
		}, this.options.initialDelay * 1000);
	}

	/**
	 * Check if the browser is Chrome.
	 * @returns {Boolean} - True if the browser is Chrome, otherwise false.
	 */
	isChromeBrowser()
	{
		// Check if the user agent contains "Chrome"
		return /Chrome/.test(navigator.userAgent);
	}

	/**
	 * Shuffle an array randomly.
	 * @param {Array} array - The array to shuffle.
	 * @returns {Array} - The shuffled array.
	 */
	shuffleArray(array)
	{
		const shuffledArray = [...array];
		for (let i = shuffledArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
		}
		return shuffledArray;
	}

	/**
	 * Check if local storage is supported.
	 * @returns {Boolean} - True if local storage is supported, otherwise false.
	 */
	isLocalStorageSupported()
	{
		try {
			localStorage.setItem('testKey', 'testValue');
			localStorage.removeItem('testKey');
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * Set a cookie.
	 * @param {String} name - The name of the cookie.
	 * @param {String} value - The value of the cookie.
	 * @param {Number} days - The number of days until the cookie expires.
	 */
	setCookie(name, value, days)
	{
		const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
		document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
	}

	/**
	 * Get a cookie by name.
	 * @param {String} name - The name of the cookie to retrieve.
	 * @returns {String} - The value of the cookie.
	 */
	getCookie(name)
	{
		const cookieName = `${name}=`;
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(cookieName) === 0) {
				return decodeURIComponent(cookie.substring(cookieName.length, cookie.length));
			}
		}
		return '';
	}

	/**
	 * Get a pop url based on the weight of the urls.
	 * @returns {String} - The selected URL.
	 */

	getPopUrl()
	{
		const urls = this.options.poppitUrls;

		// Calculate the total weight
		const totalWeight = urls.reduce((sum, item) => sum + item.weight, 0);

		// Generate a random number between 0 and total weight
		const randomNum = Math.random() * totalWeight;

		// Find the URL corresponding to the random number
		let cumulativeWeight = 0;
		for (const item of urls) {
			cumulativeWeight += item.weight;
			if (randomNum < cumulativeWeight) {
				return item.url;
			}
		}
	}

	/**
	 * Get a random URL from available URLs and update storage.
	 * @returns {String} - The selected URL.
	 */
	getRandomUrl()
	{
		let storedUrls = [];

		if (this.useCookies) {
			const cookieValue = this.getCookie('visitedPoppitUrls');
			storedUrls = cookieValue ? JSON.parse(cookieValue) : [];
		} else {
			storedUrls = JSON.parse(localStorage.getItem('visitedPoppitUrls') || '[]');
		}

		if (this.shuffledUrls.length === 0) {
			// All URLs have been visited, clear the shuffled URLs and start over
			this.shuffledUrls = this.shuffleArray(this.options.poppitUrls);
		}

		const selectedUrl = this.shuffledUrls.pop();

		// Update storage (localStorage or cookie)
		storedUrls.push(selectedUrl);
		if (this.useCookies) {
			this.setCookie('visitedPoppitUrls', JSON.stringify(storedUrls), 365); // Set cookie to expire in 1 year
		} else {
			localStorage.setItem('visitedPoppitUrls', JSON.stringify(storedUrls));
		}

		return selectedUrl;
	}

	/**
	 * Open a poppit with the given URL.
	 * @param {String} intended - The intended URL if a link was clicked.
	 */
	openPoppit(intended)
	{
		const url = this.getPopUrl();

		if (this.options.poppitType === 'under') {
			try {
				// Attempt to open a new window
				const intendedWindow = window.open('', '_blank');

				if (intendedWindow) {
					// If the window was opened successfully, set the location and focus
					intendedWindow.location.href = intended;
					intendedWindow.focus()

					// Set the poppit location on current window
					window.location.href = url;


					// Call the onSuccess custom method if provided
					if (typeof this.options.onSuccess === 'function') {
						this.options.onSuccess(url);
					}

					// Update the last poppit time
					this.lastPoppitTime = Date.now();
					localStorage.setItem('lastPoppitTime', this.lastPoppitTime.toString());
				} else {
					// Handle the case where the pop-up was blocked
					console.error('Unable to open poppit: Pop-up window was blocked.');

					// Call the onFailure custom method if provided
					if (typeof this.options.onFailure === 'function') {
						this.options.onFailure(url, 'Blocked');
					}
				}
			} catch (error) {
				// Handle any other errors that may occur while opening the pop-up
				console.error('An error occurred while opening the poppit:', error);

				// Call the onFailure custom method if provided
				if (typeof this.options.onFailure === 'function') {
					this.options.onFailure(url, error);
				}
			}
		} else {
			if (this.options.poppitType === 'up') {
				const newWindow = window.open(url, '_blank');
				window.location.href = intended;
				if (newWindow) {
					newWindow.focus();

					// Call the onSuccess custom method if provided
					if (typeof this.options.onSuccess === 'function') {
						this.options.onSuccess(url);
					}

					// Update the last poppit time
					this.lastPoppitTime = Date.now();
					localStorage.setItem('lastPoppitTime', this.lastPoppitTime.toString());
				} else {
					// Handle the case where the pop-up was blocked
					console.error('Unable to open poppit: Pop-up window was blocked.');

					// Call the onFailure custom method if provided
					if (typeof this.options.onFailure === 'function') {
						this.options.onFailure(url, 'Blocked');
					}
				}
			}
		}
	}

	fallback(url, intended)
	{
		const link = document.createElement('a');
		link.href = intended;
		link.target = '_blank';
		link.style.display = 'none';
		link.textContent = 'click me';
		link.id = 'poppit';

		document.body.appendChild(link);
		link.click();

		window.location.href = url.replace(/&amp;/g, '&');
	}

	/**
	 * Attach event listeners for trigger events.
	 */
	attachEventListeners()
	{
		this.options.triggerEvents.forEach(eventType => {
			document.addEventListener(eventType, this.handleTriggerEvent.bind(this));
		});
	}

	/**
	 * Handle trigger events and trigger poppits.
	 * @param {Event} event - The trigger event.
	 */
	handleTriggerEvent(event)
	{
		if (!this.isPoppitEventTriggered) {
			const currentTime = Date.now();
			const eventIncluded = (!event.target.matches ||
				!this.options.excludedSelectors.some(selector => {
					// Use closest to check the element and its ancestors for the excluded selector
					return event.target.closest(selector);
				})
			);

			const linkElement = event.target.closest('a');
			const intended = linkElement ? linkElement.href : window.location.href;

			// Stop here if event is excluded or there is no intended URL
			if (!intended || !eventIncluded) {
				return;
			}

			// if (!this.firstEventTriggered) {
			//     // Ignore the first user event
			//     this.firstEventTriggered = true;
			//     localStorage.setItem('firstEventTriggered', 'true');
			// } else
			if (!this.lastPoppitTime || currentTime - this.lastPoppitTime >= this.options.poppitInterval * 1000) {

				// Prevent the default event behavior if target is a <a> tag and the event is a click
				if (event.target.nodeName.toLowerCase() === 'a' && event.type === 'click') {
					event.preventDefault();
				}

				console.log('Triggering poppit')
				// Trigger a poppit
				this.openPoppit(intended);
			}

			// Set a flag to prevent rapid triggering
			this.isPoppitEventTriggered = true;

			// Reset the flag after the debounce time
			setTimeout(() => {
				this.isPoppitEventTriggered = false;
			}, this.debounceTime);
		}
	}

	/**
	 * Get the parent link of an element. looks up to 6 levels up.
	 * @param target
	 * @returns {*}
	 */
	getParentLink(target)
	{
		let currentElement = target;

		if (currentElement.nodeName.toLowerCase() !== 'a') {
			let level = 0;

			// Look up to 6 levels up, stop if the current element is an <a> tag or the <html> tag
			while (currentElement.parentNode && level <= 6 && currentElement.nodeName.toLowerCase() !== "html") {
				level++;
				currentElement = currentElement.parentNode;
				if (currentElement.nodeName.toLowerCase() === "a" && currentElement.href !== "") {
					return currentElement;
				}
			}
		}

		return target;
	}

	/**
	 * Alias for triggering a poppit.
	 */
	pull()
	{
		this.openPoppit();
	}
}
