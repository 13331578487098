<script setup>

import {VideoPlayer} from "@videojs-player/vue";
import videojs from "video.js";
import 'video.js/dist/video-js.css'
import {computed, ref, watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import {useVideoStore} from "@/Stores/VideoStore";
import {useSwiperSlide} from "swiper/vue";
import {PlayIcon} from "@heroicons/vue/24/outline";

const swiperSlide = useSwiperSlide();
const videoStore = useVideoStore();
const settings = ref(usePage().props.settings);
const video = ref(null);
const showPlayButton = ref(false);

const props = defineProps({
	sources: {type: Object, required: true},
	tech: {type: String},
	poster: {type: String},
	autoplay: {type: Boolean, default: false}
});

const emit = defineEmits(['ended']);

const src = computed(() => props.sources[tech.value]);

const tech = computed(() => props.tech || settings.value.video_tech);

watch(() => swiperSlide.value, (slide) => {

	// exit if post is not a video type
	if (!video.value) {
		return;
	}

	// store current video
	if (slide.isActive) {
		video.value.player.play()
		videoStore.setCurrentVideo(video.value)
	}

	// reset inactive video
	if (!slide.isActive) {
		video.value.player.muted(true)
		video.value.player.pause()
		video.value.player.currentTime(0.5)
	}
}, {immediate: false, deep: true})

watch(() => video.value?.state, (state) => {
	if (!state) {
		return;
	}
	if (state.paused) {
		setTimeout(() => {
			showPlayButton.value
		}, 500)
	}
}, {deep: true, immediate: true})

const onVideoMounted = (player) => {
	video.value = player
}

const onVideoEnded = () => {
	emit('ended');
}

const handleVideoJsReady = () => {

	if (tech.value !== 'hls') {
		return;
	}

	// https://github.com/videojs/http-streaming#vhsxhr
	const {vhs} = video.value?.player.tech();
	vhs.xhr.beforeRequest = (options) => {
		// console.log('vhs.xhr.beforeRequest', options)
		// do something...
		return options
	}
}
</script>

<template>

	<VideoPlayer
		ref="player"
		:autoplay="autoplay"
		:fill="true"
		:html5="{
                vhs: {
                    overrideNative: !videojs.browser.IS_SAFARI,
                    maxPlaylistRetries: Infinity,
                    limitRenditionByPlayerDimensions: false,
                    useBandwidthFromLocalStorage: true,
                },
                nativeAudioTracks: false,
                nativeVideoTracks: false
            }"
		:loop="settings.loop"
		:sources="[{
                src: src.url + '#t=0.5',
                type: src.mimetype
            }]"
		class="min-h-full min-w-full object-cover"
		crossorigin="anonymous"
		muted
		playsinline
		preload="metadata"
		@ended="onVideoEnded"
		@mounted="onVideoMounted"
		@ready="handleVideoJsReady"
	>
		<template v-slot="{ player, state }">
			<div v-if="showPlayButton" class="absolute z-50 w-full h-full flex items-center justify-center flex-col">
				<button type="button" @click="player.play()">
					<PlayIcon class="w-20 h-20"/>
				</button>
			</div>
			<div class="absolute bottom-0 z-50 w-full bg-gray-200 h-0.5 dark:bg-gray-700">
				<div :style="{width: Math.round((state.currentTime / state.duration) * 100)+'%'}"
				     class="bg-dark-500 h-0.5 dark:bg-dark-500"></div>
			</div>
		</template>
	</VideoPlayer>
</template>

<style>
video, .vjs-tech {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.vjs-waiting > .vjs-poster {
	display: block;
	background-size: cover !important;
	background-position: center !important;

}
</style>
