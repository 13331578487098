<script setup>
import {onMounted} from "vue";
import {CheckCircleIcon, ExclamationCircleIcon, XMarkIcon} from "@heroicons/vue/24/outline"

const props = defineProps({
	notification: {
		type: Object,
		required: true
	},
	duration: {
		type: Number,
		default: 2000
	}
});

onMounted(() => {
	setTimeout(() => emit("remove"), props.duration);
});

const emit = defineEmits(["remove"]);
</script>
<template>
	<div :class="{
            'text-green-800 bg-green-50' : props.notification.type === 'success',
            'text-red-800 bg-red-50' : props.notification.type === 'error'
         }"
	     class="rounded-md bg-green-50 p-4 max-w-sm mx-auto">
		<div class="flex">
			<div class="flex-shrink-0">
				<CheckCircleIcon v-if="notification.type === 'success'" class="text-green-400 h-6 w-6"/>
				<ExclamationCircleIcon v-if="notification.type === 'error'" class="text-red-400 h-6 w-6"/>
			</div>
			<div class="ml-3">
				<p class="text-sm font-medium">{{ notification.message }}</p>
			</div>
			<div class="ml-auto pl-3">
				<div class="-mx-1.5 -my-1.5">
					<button
						:class="{
                            'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50' : notification.type === 'success',
                            'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50' : notification.type === 'error'
                         }"
						aria-label="Close"
						class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
						data-dismiss-target="#toast-default"
						type="button"
						@click="emit('remove')"
					>
						<span class="sr-only">Dismiss</span>
						<XMarkIcon class="h-6 w-6"/>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
