<script setup>

import {onMounted} from "vue";
import 'video.js/dist/video-js.css'
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";

const analyticsStore = useAnalyticsStore();

onMounted(() => {
	analyticsStore.sendAnalyticsEvent('ad_impression', {
		adType: 'Interstitial'
	});
})


const pushGAEvent = () => {
	analyticsStore.sendAnalyticsEvent('ad_click', {
		adType: 'Interstitial'
	});
}
</script>

<template>

	<div class="relative flex h-full w-full flex-col">
		<a class="absolute block z-10 h-full overflow-hidden w-full"
		   href="https://hotadultplaytime.life/?u=5mekd06&o=g58pl9p&t=3sex-interstitial"
		   target="_blank" @click="pushGAEvent">
		</a>
		<object class="absolute z-0 w-full h-full border-none inline"
		        data="https://hotadultplaytime.life/?u=5mekd06&o=g58pl9p&t=3sex-interstitial"/>
	</div>
</template>
