<template></template>
<script setup>
import {onMounted, onUnmounted, ref} from 'vue';
import {usePage} from "@inertiajs/vue3";

const userAgent = navigator.userAgent.toLowerCase();
const back_button = ref(usePage().props.ads.back_button);

onMounted(() => {
    if (userAgent.includes('ucbrowser') || userAgent.includes('ucweb')) {
        // do nothing
    } else {
        // Check if the user should be redirected based on your conditions
        if (back_button.value.should_redirect) {
            // Push empty states to history
            for (let t = 0; t < 10; ++t) {
                history.pushState({}, "", "");
            }

            // Attach onpopstate event handler
            window.addEventListener('popstate', handlePopState);
        }
    }
});

onUnmounted(() => {
    window.removeEventListener('popstate', handlePopState);
});

// Handle back button
const handlePopState = (event) => {
    if (event.state) {
        location.replace(back_button.value.url);
    }
};
</script>

