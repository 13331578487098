<script setup>
import {onUnmounted, ref} from "vue";
import {useToastNotificationsStore} from "@/Stores/ToastNotificationsStore";
import ToastListItem from "@/Components/ToastNotifications/ToastListItem.vue";
import {router, usePage} from "@inertiajs/vue3";

const success = ref(usePage().props.flash.success);
const error = ref(usePage().props.flash.error);
const verified = ref(usePage().props.flash.verified);
const toastNotificationsStore = useToastNotificationsStore()

let removeFinishEventListener = router.on("finish", () => {
    if (success.value) {
        toastNotificationsStore.add({message: success.value, type: 'success'});
    }
    if (error.value) {
        toastNotificationsStore.add({message: error.value, type: 'error'});
    }
    if (verified.value) {
        toastNotificationsStore.add({message: verified.value, type: 'verified'});
    }
});

onUnmounted(() => removeFinishEventListener());

function remove(index) {
    toastNotificationsStore.remove(index);
}
</script>
<template>
    <TransitionGroup
        class="fixed p-2 z-50 w-full space-y-2"
        enter-active-class="duration-500"
        enter-from-class="translate-x-full opacity-0"
        leave-active-class="duration-500"
        leave-to-class="translate-x-full opacity-0"
        tag="div">
        <ToastListItem
            v-for="(notification, index) in toastNotificationsStore.notifications"
            :key="notification.key"
            :duration="3000"
            :notification="notification"
            @remove="remove(index)"
        />
    </TransitionGroup>
</template>
