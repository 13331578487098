<template>
	<div class="flex h-screen w-screen flex-col">
		<div class="z-[1] flex h-full w-full max-w-2xl flex-col justify-center items-center p-4">
			<iframe id='a724b1a9'
			        :src='"https://revive.videobaba.xyz/revive/www/delivery/afr.php?zoneid=296&amp;cb="+cb'
			        allow='autoplay'
			        height='250'
			        name='a724b1a9'
			        width='300'>
			</iframe>
		</div>
	</div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";

const analyticsStore = useAnalyticsStore();
const cb = ref(Math.random())

onMounted(() => {
	analyticsStore.sendAnalyticsEvent('ad_impression', {
		adType: 'Revive Native'
	});
	analyticsStore.sendAnalyticsEvent('revive_ad_impression');
})
</script>
