<script setup>
import {usePostStore} from "@/Stores/PostStore";
import {storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {Link, usePage} from "@inertiajs/vue3";

const postStore = usePostStore()
const {post} = storeToRefs(postStore)

</script>
<template>
	<div v-if="post" class="flex flex-1 flex-col text-white space-y-1">

		<div v-if="post.creator.public" class="flex items-center space-x-2 font-bold">
			<h2>
				<Link :href="route('creator.show', post.creator.slug)">
					@{{ post.creator.name }}
				</Link>
			</h2>
			<button :class="{
                        'bg-white text-dark-900' : post.creator.meta.following,
                        'hover:bg-dark-50 hover:text-dark-900' : !post.creator.meta.following,
                    }" class="border text-xs font-bold rounded px-2 py-0.5"
			        type="button"
			        @click="postStore.follow_unfollow()">
				<span v-if="post.creator.meta.following">Following</span>
				<span v-else>Follow</span>
			</button>
		</div>

		<h1>{{ post.title }}</h1>
		<ul class="">
			<li v-for="tag in post.tags" :key="tag.id"
			    class="inline-flex mr-1 mb-1 rounded bg-dark-300 bg-opacity-25 text-white font-semibold text-sm px-2 py-0.5">
				<Link :href="route('tag.show', tag.slug)">#{{ tag.name }}</Link>
			</li>
		</ul>
	</div>
</template>
