import {defineStore} from "pinia";
import {gtag} from "ga-gtag";

export const useAnalyticsStore = defineStore("analytics-store", () => {

    const sendGoogleAnalyticsEvent = (event, data) => {
        gtag('event', event, data)
    }

    const sendPlausibleEvent = (event, data) => {
        if (window.plausible) {
            window.plausible(event, {props: data});
        } else {
            window.axios.post('https://stats.indianpornempire.com/api/event', {
                name: event,
                url: document.location.href,
                domain: document.location.hostname,
                props: data
            }, {
                headers: {
                    // 'User-Agent': window.navigator.userAgent,
                    'Content-Type': 'application/json'
                }
            })
        }
    }

    const sendAnalyticsEvent = (event, data) => {
        sendGoogleAnalyticsEvent(event, data);
        sendPlausibleEvent(event, data);
    }

    return {
        sendGoogleAnalyticsEvent,
        sendPlausibleEvent,
        sendAnalyticsEvent
    }
});
