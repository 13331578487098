<script setup>

import {computed, onMounted, ref} from "vue";
import {ArrowUpOnSquareIcon, ChevronRightIcon, CloudArrowDownIcon, SquaresPlusIcon} from "@heroicons/vue/24/outline";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";
import {useWpaInstallStore} from "@/Stores/WpaInstallStore";

const analyticsStore = useAnalyticsStore();
const wpaInstallStore = useWpaInstallStore();
const appName = ref(import.meta.env.VITE_APP_NAME)
const ios = computed(() => !!navigator.userAgent.match(/iPhone|iPad/i));
const android = computed(() => !!navigator.userAgent.match(/Android/i));
const chrome = computed(() => !!navigator.userAgent.match(/Chrome/i));
const lang = computed(() => ['hindi', 'english', 'hindish'][Math.floor(Math.random() * 3)]);
const color = computed(() => ['red', 'blue', 'green'][Math.floor(Math.random() * 3)]);

const slideSettings = computed(() => [
	{
		lang: 'hindi',
		color: 'green'
	}, {
		lang: 'hindi',
		color: 'blue'
	}, {
		lang: 'english',
		color: 'green'
	}, {
		lang: 'hindish',
		color: 'blue'
	}
][Math.floor(Math.random() * 4)])

const title = computed(() => {
	if (android.value || chrome.value) {

		switch (lang.value) {
			case 'english':
				return appName.value + " now available as an app!";
			case 'hindi':
				return "3सेक्स का एक मुफ़्त ऐप है - " + appName.value;
			case 'hindish':
				return "3seks ka ek muft aip hai - " + appName.value;
		}
	}
	if (ios.value) {
		return "How to install " + appName.value + " on iOS";
	}

})

onMounted(() => {
	analyticsStore.sendAnalyticsEvent('ad_impression', {
		adType: 'Install PWA Banner - ' + slideSettings.value.color + ' - ' + slideSettings.value.lang
	});
})
</script>

<template>

	<div class="bg-dark-900 exclude-poppit flex flex-col h-full justify-end w-full p-4"
	     @click="wpaInstallStore.showInAppInstallPromotion('Banner - ' + slideSettings.color + ' - ' + slideSettings.lang)">

		<div class="flex flex-col items-center justify-center">
			<img alt="icon" class="aspect-square w-28 h-28" src="/images/icons/icon-128x128.png"/>
			<img alt="Download Now" class="aspect-square max-h-48" src="/images/arrow-down.gif"/>
		</div>

		<div :class="{
                'bg-red-600': slideSettings.color === 'red',
                'bg-blue-600': slideSettings.color === 'blue',
                'bg-green-600': slideSettings.color === 'green'
             }"
		     class="relative flex flex-col justify-between w-full p-8 rounded-xl">
			<h2 class="mb-4 font-semibold text-xl text-dark-100">{{ title }}</h2>

			<div class="flex flex-col items-center">
				<!--                <p v-if="lang === 'english'" class="py-4">-->
				<!--                    Download the FREE {{ appName }} app for all your favorite Indian content.<br/>-->
				<!--                    Free Fun, Big Screen:<br/>-->
				<!--                    Enjoy thousands of Indian videos, FREE on your mobile device.-->
				<!--                    optimized for seamless browsing on your phone.-->
				<!--                    Download the FREE {{ appName }} App Today!-->
				<!--                </p>-->
				<div v-if="slideSettings.lang === 'english'" class="py-4">
					<ul class="list-disc ml-10">
						<li>Enjoy 1000’s of Indian videos, FREE on your mobile device.</li>
						<li>Optimized for your phone.</li>
						<li>Enjoy less ads in the app</li>
						<li>Free Fun, Big Screen.</li>
					</ul>
				</div>
				<div v-if="slideSettings.lang === 'hindi'" class="py-4">
					<ul class="list-disc ml-10">
						<li>अपने मोबाइल डिवाइस पर मुफ़्त में हज़ारों भारतीय वीडियो का आनंद लें।</li>
						<li>आपके फ़ोन के लिए अनुकूलित.</li>
						<li>एप में कम एड्स के साथ एन्जॉय करें</li>
						<li>निःशुल्क मनोरंजन, बड़ी स्क्रीन।</li>
					</ul>
				</div>
				<div v-if="slideSettings.lang === 'hindish'" class="py-4">
					<ul class="list-disc ml-10">
						<li>apane mobail divais par muft mein hazaaron bhaarateey veediyo ka aanand len.</li>
						<li>aapake fon ke lie anukoolit.</li>
						<li>App me kam ads ke sath enjoy kare</li>
						<li>nihshulk manoranjan, badee skreen.</li>
					</ul>
				</div>
			</div>

			<div v-if="ios" class="flex flex-row">
				<div class="flex-shrink-0 flex flex-col items-center w-20 text-dark-800">
					<div class="h-14 w-14 p-4 rounded-full bg-dark-300 flex-shrink-0">
						<ArrowUpOnSquareIcon class="h-full"/>
					</div>
					<div class="font-semibold text-xs mt-2 text-center text-dark-100">Tap Share</div>
				</div>

				<div class="h-14 flex flex-col justify-center text-dark-800">
					<ChevronRightIcon class="h-8"/>
				</div>

				<div class="flex-shrink-0 flex flex-col items-center w-20 text-dark-800">
					<div class="h-14 w-14 p-4 rounded-full bg-dark-300 flex-shrink-0">
						<SquaresPlusIcon class="h-full"/>
					</div>
					<div class="font-semibold text-xs mt-2 text-center text-dark-100">Add to Homescreen</div>
				</div>

				<div class="h-14 flex flex-col justify-center text-dark-800">
					<ChevronRightIcon class="h-8"/>
				</div>

				<div class="flex-shrink-0 flex flex-col items-center w-20 text-dark-100 ">
					<div class="flex-shrink-0">
						<img :alt="appName" class="h-16 w-16" src="/images/icons/icon-192x192.png"/>
					</div>
					<div class="font-semibold text-xs mt-2 text-center text-dark-100">Add {{ appName }}</div>
				</div>
			</div>

			<template v-else>
				<button
					class="active:bg-dark-100 exclude-poppit bg-dark-100 border border-transparent disabled:opacity-25 exclude-poppit focus:border-dark-100 focus:outline-none focus:ring focus:ring-dark-100 font-semibold text-sm hover:bg-dark-100 inline-flex items-center justify-center px-6 py-3 rounded-full text-dark-900 tracking-widest transition uppercase w-full"
					@click="wpaInstallStore.showInAppInstallPromotion('Banner - ' + slideSettings.color + ' - ' + slideSettings.lang)">
					<CloudArrowDownIcon class="h-6 mr-2"/>
					<span v-if="slideSettings.lang === 'english'">Install {{ appName }} App</span>
					<span v-if="slideSettings.lang === 'hindi'">मसाला रियल्स ऐप इंस्टॉल करें (3सेक्स)</span>
					<span v-if="slideSettings.lang === 'hindish'">{{ appName }} aip instol karen</span>
				</button>
			</template>
		</div>
	</div>

</template>
