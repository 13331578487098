<script setup>
import {usePostStore} from "@/Stores/PostStore";
import {storeToRefs} from "pinia";
import {useSwiperSlide} from "swiper/vue";

const swiperSlide = useSwiperSlide();
const postStore = usePostStore()
const {post} = storeToRefs(postStore)

</script>
<template>
	<div class="max-w-auto h-full">
		<img :alt="post.title" :loading="swiperSlide.isActive ? 'eager' : 'lazy'" :src="post.media_url"
		     class="w-full h-full object-cover rounded-lg"/>
		<div class="swiper-lazy-preloader"></div>
	</div>
</template>
