<script setup>
import {
    ArrowUpOnSquareIcon,
    ChevronRightIcon,
    CloudArrowDownIcon,
    SquaresPlusIcon,
    XMarkIcon
} from "@heroicons/vue/24/outline";
import {computed, onMounted, ref} from "vue";
import PrimaryButton from "@/Shared/PrimaryButton.vue";
import {useCookies} from '@vueuse/integrations/useCookies'
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";
import {useWpaInstallStore} from "@/Stores/WpaInstallStore";
import {storeToRefs} from "pinia";

const cookies = useCookies()

const wpaInstallStore = useWpaInstallStore();
const {enabled} = storeToRefs(wpaInstallStore)
const appName = ref(import.meta.env.VITE_APP_NAME)
const show = ref(false)
const label = ref('Alert30')
const dismissed = computed(() => cookies.get('pwa_dismissed') || false);
const ios = computed(() => !!navigator.userAgent.match(/iPhone|iPad/i));
const android = computed(() => !!navigator.userAgent.match(/Android/i));
const chrome = computed(() => !!navigator.userAgent.match(/Chrome/i));
const analyticsStore = useAnalyticsStore();

const title = computed(() => {
	if (android.value || chrome.value) {
		return appName.value + " now available as an app!";
	}
	if (ios.value) {
		return "How to install " + appName.value + " on iOS";
	}

})

onMounted(() => {
	init()
})

const init = () => {
	// skip if the user on these pages
	if (window.location.pathname.startsWith('/register') ||
		window.location.pathname.startsWith('/login') ||
		window.location.pathname.startsWith('/user') ||
		window.location.pathname.startsWith('/forgot-password') ||
		window.location.pathname.startsWith('/reset-password') ||
		window.location.pathname.startsWith('/email')
	) {
		return;
	}

	setTimeout(() => {
		show.value = true
		label.value = 'Alert30'

		analyticsStore.sendAnalyticsEvent('ad_impression', {
			adType: 'Install PWA Alert'
		});

	}, 30000)

	setTimeout(() => {
		show.value = true
		label.value = 'Alert240'

		analyticsStore.sendAnalyticsEvent('ad_impression', {
			adType: 'Install PWA Alert'
		});

	}, 240000)
}

const install = () => {
	wpaInstallStore.showInAppInstallPromotion(label.value);
	label.value = ''
	show.value = false;
}

const dismiss = () => {
	//cookies.set('pwa_dismissed', true);
	show.value = false;
	label.value = ''
}
</script>

<template>
	<div v-if="show && enabled && !dismissed"
	     class="exclude-poppit fixed z-50 bottom-16 px-3 w-full flex flex-col justify-center items-center">
		<div class="relative flex flex-col items-center max-w-md w-full p-4 rounded-md bg-dark-100">
			<button
				class="exclude-poppit absolute top-0 right-0 p-2 w-8 h-8 hover:scale-110 cursor-pointer text-dark-300"
				type="button"
				@click="dismiss">
				<XMarkIcon class="h-full"/>
			</button>
			<div class="mb-4 font-semibold text-center  text-dark-800">{{ title }}</div>
			<div v-if="ios" class="flex flex-row">
				<div class="flex-shrink-0 flex flex-col items-center w-20 text-dark-800">
					<div class="h-14 w-14 p-4 rounded-full bg-dark-300 flex-shrink-0">
						<ArrowUpOnSquareIcon class="h-full"/>
					</div>
					<div class="font-semibold text-xs mt-2 text-center">Tap Share</div>
				</div>

				<div class="h-14 flex flex-col justify-center text-dark-800">
					<ChevronRightIcon class="h-8"/>
				</div>

				<div class="flex-shrink-0 flex flex-col items-center w-20 text-dark-800">
					<div class="h-14 w-14 p-4 rounded-full bg-dark-300 flex-shrink-0">
						<SquaresPlusIcon class="h-full"/>
					</div>
					<div class="font-semibold text-xs mt-2 text-center">Add to Homescreen</div>
				</div>

				<div class="h-14 flex flex-col justify-center text-dark-800">
					<ChevronRightIcon class="h-8"/>
				</div>

				<div class="flex-shrink-0 flex flex-col items-center w-20 text-dark-100">
					<div class="flex-shrink-0">
						<img :alt="appName" class="h-16 w-16" src="/images/icons/icon-192x192.png"/>
					</div>
					<div class="font-semibold text-xs mt-2 text-center  text-dark-800">Add {{ appName }}</div>
				</div>
			</div>

			<div v-else>
				<PrimaryButton class="font-medium exclude-poppit" type="button"
				               @click="install">
					<CloudArrowDownIcon class="h-6 mr-2"/>
					<span>Install {{ appName }} App!</span>
				</PrimaryButton>
			</div>
		</div>
	</div>
</template>
