<script setup>
import {computed, onMounted} from "vue";
import 'video.js/dist/video-js.css'
import PostVideo from "@/Pages/Post/Partials/PostVideo.vue";
import {storeToRefs} from "pinia";
import {useVideoStore} from "@/Stores/VideoStore";
import {useAdStore} from "@/Stores/AdStore";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";
import {ChatBubbleLeftEllipsisIcon, SpeakerWaveIcon, SpeakerXMarkIcon} from "@heroicons/vue/24/outline";

const props = defineProps({
	model: {type: Object, required: true},
	active: Boolean
})

const adStore = useAdStore();
const analyticsStore = useAnalyticsStore();
const videoStore = useVideoStore()
const {video} = storeToRefs(videoStore)
const modelUrl = computed(() => {
	return "https://go.rmshqa.com?userId=245ca507766e740acb84f0b39e2382497db6cf06625168391b231a62bbb26515&sourceId=" + import.meta.env.VITE_APP_NAME + "&targetDomain=hot-india.com&onlineModels=" + props.model.username
})

const sources = computed(() => {
	return {
		'hls': {
			'url': props.model.stream.url,
			'mimetype': 'application/x-mpegURL'
		}
	}
})

onMounted(() => {
	analyticsStore.sendAnalyticsEvent('ad_impression', {
		adType: 'Stripchat'
	});
	analyticsStore.sendAnalyticsEvent('strip_ad_impression', {
		modelName: props.model.username
	});
})
const pushGAEvent = () => {
	analyticsStore.sendAnalyticsEvent('ad_click', {
		adType: 'Stripchat'
	});
	analyticsStore.sendAnalyticsEvent('strip_ad_click', {
		modelName: props.model.username
	});
}
</script>

<template>

	<div class="exclude-poppit flex h-full w-full flex-col">

		<div class="z-50 flex h-full w-full flex-col justify-between p-4">
			<div class="flex w-full items-center justify-end">
				<button
					class="h-12 w-12 p-3 rounded-full cursor-pointer outline-hidden bg-white transition-colors duration-200 ease-in text-white bg-opacity-20 hover-hover:hover:bg-opacity-20"
					type="button"
					@click="videoStore.toggleModelAudio()">
					<div class="flex justify-center items-center object-center h-full w-full">
						<SpeakerXMarkIcon v-if="video && video.state.muted" class="h-6"/>
						<SpeakerWaveIcon class="h-6"/>
					</div>
				</button>
			</div>

			<a :href="modelUrl" class="exclude-poppit flex-1" rel="noopener nofollow" target="_blank"
			   @click.once="pushGAEvent">
				<div class=""></div>
			</a>

			<div class="w-full flex flex-col m-auto overflow-hidden">
				<a :href="modelUrl" class="exclude-poppit" rel="noopener nofollow" target="_blank"
				   @click.once="pushGAEvent">
					<div class="flex flex-row space-x-4 items-center">
						<div class="flex-grow h-0.5 opacity-20 bg-white"></div>
						<div class="flex font-bold cursor-pointer space-x-2 py-4 leading-4 items-center">
							<ChatBubbleLeftEllipsisIcon class="w-5"/>
							<div class="grow-1">Tap to chat</div>
						</div>
						<div class="flex-grow h-0.5 opacity-20 bg-white"></div>
					</div>
					<div class="flex flex-row space-x-4">
						<div>
							<div
								class="h-16 w-16 rounded-full border-2 overflow-hidden border-primary-600 select-none cursor-pointer bg-dark-900">
								<img
									:src="model.avatarUrl || model.previewUrlThumbSmall" alt=""
									class="object-fit object-center rounded-full overflow-hidden">
							</div>
						</div>
						<div class="flex flex-col justify-center items-start space-y-2">
							<div
								class="inline-flex h-5 items-center justify-center rounded-md bg-black bg-opacity-50 px-2">
					        <span class="relative flex h-2 w-2">
						        <span
							        class="blinking absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                            </span>
								<span class="leading-0 ml-1 text-xs font-bold text-white">LIVE</span>
							</div>
							<div>
								<h1 class="text-base font-bold m-0 p-0 select-none cursor-pointer">
									@{{ model.username }}
								</h1>
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>

		<div class="absolute z-10 h-full overflow-hidden w-full">
			<PostVideo :autoplay="true" :sources="sources" tech="hls"/>
		</div>
	</div>

</template>
