<script setup>
import {usePostStore} from "@/Stores/PostStore";
import {storeToRefs} from "pinia";
import {
    ArrowDownTrayIcon,
    EllipsisHorizontalIcon,
    HeartIcon,
    LinkIcon,
    SpeakerWaveIcon,
    SpeakerXMarkIcon
} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartIconSolid} from "@heroicons/vue/24/solid";
import Avatar from "@/Pages/Ads/Partials/Avatar.vue";
import {useVideoStore} from "@/Stores/VideoStore";

const postStore = usePostStore()
const videoStore = useVideoStore();
const {video} = storeToRefs(videoStore)
const {post} = storeToRefs(postStore)
const download = false; // computed(() => post.value.type === 'video' ? post.value.media.mp4?.url : null);
</script>

<template>
    <div v-if="post" class="flex flex-col space-y-6 exclude-poppit">
        <div class="text-white justify-center flex">
            <Avatar/>
        </div>
        <div class="text-white justify-center flex">
            <div class="flex flex-col">
                <button aria-label="Like/Unlike Post" class="outline-none" type="button"
                        @click="postStore.like_unlike()">
                    <HeartIconSolid v-if="post.meta.isLiked" class="h-6 md:h-8 text-red-600"/>
                    <HeartIcon v-else class="h-6 md:h-8"/>
                </button>
                <span class="text-xs text-center">{{ post.meta.likesCount }}</span>
            </div>
        </div>
        <div v-if="post.type==='video'" class="text-white justify-center flex">
            <button aria-label="Mute/Unmute Video" class="outline-none" type="button"
                    @click="videoStore.toggleModelAudio()">
                <SpeakerXMarkIcon v-if="video && video.state.muted"
                                  class="h-6 md:h-8"/>
                <SpeakerWaveIcon v-else class="h-6 md:h-8"/>
            </button>
        </div>

        <div class="text-white justify-center flex">
            <button aria-label="Share Post" class="outline-none" type="button"
                    @click="postStore.share()">
                <LinkIcon class="h-6 md:h-8"/>
            </button>
        </div>

        <div v-if="download" class="text-white justify-center flex">
            <a :download="post.slug + '.mp4'" :href="download" class="outline-none">
                <ArrowDownTrayIcon class="h-6 md:h-8"/>
            </a>
        </div>

        <div class="text-white justify-center flex">
            <button aria-label="Open Post Options Menu" class="outline-none" type="button"
                    @click="postStore.toggleMenu()">
                <EllipsisHorizontalIcon class="h-6 md:h-8"/>
            </button>
        </div>
    </div>
</template>

<style scoped>

</style>
