import './bootstrap';
import '../css/app.css';

import {createSSRApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';
import {createPinia} from 'pinia';

const pinia = createPinia();

const appName = import.meta.env.VITE_APP_NAME || 'Pitpot';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({el, App, props, plugin})
	{
		return createSSRApp({render: () => h(App, props)})
			.use(plugin)
			.use(ZiggyVue)
			.use(pinia)
			.mount(el);
	},
	progress: {
		color: '#991b1b',
	},
});
