import axios from 'axios';
import {gtag, install} from 'ga-gtag';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Google Analytics
 */
install(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
gtag('set', {
	'js': new Date(),
	'country': 'IN',
});