import {defineStore} from "pinia";
import {computed, onMounted, ref, watch} from "vue";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";

export const useWpaInstallStore = defineStore("wpa-install", () => {
	const enabled = ref(false);
	const show = ref(false);
	const prompt = ref(null);
	const mobile = computed(() => window.innerWidth < 768);
	const android = computed(() => !!navigator.userAgent.match(/Android/i));
	const pwa_visitor = computed(
		() => window.matchMedia('(display-mode: window-controls-overlay)').matches || window.matchMedia(
			'(display-mode: standalone)').matches);
	const analyticsStore = useAnalyticsStore();

	watch(
		() => prompt.value,
		(value) => {
			enabled.value = (mobile.value && !android.value && !pwa_visitor.value) || !!value;
		},
		{immediate: true},
	);

	onMounted(async () => {
		window.addEventListener('beforeinstallprompt', async (event) => {
			// Prevent the default behavior to immediately show the prompt
			event.preventDefault();

			// store the event for later
			prompt.value = event;
		});

		window.addEventListener('appinstalled', () => {
			prompt.value = null;
			console.log('PWA was installed');
		});
	});

	const showInAppInstallPromotion = async (media) => {

		if (prompt.value) {
			// Show the prompt
			prompt.value.prompt();

			// Wait for the user to respond to the prompt
			const {outcome} = await prompt.value.userChoice;

			// Act on the user's choice
			if (outcome === 'accepted') {

				prompt.value = null;

				analyticsStore.sendAnalyticsEvent('pwa_install', {
					adType: 'PWA ' + media,
				});
			} else {
				if (outcome === 'dismissed') {
					analyticsStore.sendAnalyticsEvent('pwa_dismiss', {
						adType: 'PWA ' + media,
					});
				}
			}
		}
	};

	return {show, enabled, pwa_visitor, showInAppInstallPromotion};
});
