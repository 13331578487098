<script setup>
import {ref} from "vue";
import {gtag} from "ga-gtag";
import {usePage} from "@inertiajs/vue3";

const ad = ref(usePage().props?.ads?.avatar)

const pushGAEvent = () => {
    gtag('event', 'avatar_ad_click', {
        modelUrl: ad.value.url
    })
}
</script>

<template>
    <div v-if="ad"
         class="w-10 h-10 rounded-full flex items-center justify-center overflow-hidden border-2 relative mb-1 pulse border-pink-600">
        <a :href="ad.url" target="_blank" @click.once="pushGAEvent">
            <div class="absolute top-0 w-10 flex justify-center">
                <span
                    class="font-bold text-center rounded-b select-none px-0.5 bg-pink-600 text-[0.5rem]">
                    LIVE
                </span>
            </div>
            <img :src="ad.thumb" alt="Live Model" class="h-10 w-10 object-cover" loading="eager">
        </a>
    </div>
</template>
