<script setup>
import {computed, onUnmounted, ref} from "vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';

import AppLayout from "@/Layouts/AppLayout.vue";
import PostCard from "@/Pages/Post/Partials/PostCard.vue";
import Ad from "@/Pages/Ads/Ad.vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import {usePostStore} from "@/Stores/PostStore";
import {useVideoStore} from "@/Stores/VideoStore";
import {useAdStore} from "@/Stores/AdStore";
import InstallPWASlide from "@/Shared/InstallPWASlide.vue";
import {useWpaInstallStore} from "@/Stores/WpaInstallStore";
import {Mousewheel, Virtual} from "swiper/modules";
import {ArrowLeftIcon, SignalIcon} from "@heroicons/vue/24/outline/index.js";

const page = ref(1)
const swiperObj = ref(null)

const props = defineProps({
	posts: Object,
	page: Number,
});

const settings = ref(usePage().props.settings)
const adIndexes = ref([])
const slides = ref(props.posts.data)
const postStore = usePostStore()
const videoStore = useVideoStore()
const adStore = useAdStore();
const pwaInstallStore = useWpaInstallStore();

const modules = computed(() => [Mousewheel, Virtual]);

const onSwiper = (swiper) => {
	swiperObj.value = swiper
	adStore.setSwiper(swiper)
}

const onSlideChange = (swiper) => {

	// check if user is not using the pwa and prompt is ready to show
	if (pwaInstallStore.enabled) {
		// on every 5th slide, show install pwa slide
		if (swiper.realIndex % settings.value.pwa_ad_interval === 0 && !adIndexes.value.includes(swiper.realIndex)) {

			pwaInstallStore.show = false;

			slides.value.splice(swiper.realIndex, 0, {
				'resource': 'pwa',
				'id': Math.random()
			})
		}
	}

	if (settings.value.ads_enabled && swiper.realIndex % settings.value.ads_interval === 0 && !adIndexes.value.includes(
		swiper.realIndex)) {

		adIndexes.value.push(swiper.realIndex)

		slides.value.splice(swiper.realIndex, 0, {
			'resource': 'ad',
			'id': Math.random()
		})
	}
}

const onSwipeNext = (swiper) => {
	if (adIndexes.value.includes(swiper.realIndex)) {
		adStore.lockAd()
	}
}

const onReachEnd = (swiper) => {

	router.reload({
		headers: {
			'x-page': props.page + 1,
		},
		only: ['posts', 'page'],
		preserveScroll: true,
		preserveState: false,
		onSuccess: () => {
			if (props.posts.data.length) {
				if (!slides.value || props.page === 1) {
					slides.value = props.posts.data
				} else {
					slides.value.push(...props.posts.data)
				}
			}
		},
	})
}

const swipeNext = () => {
	swiperObj.value.slideNext()
}

onUnmounted(() => {
	postStore.$dispose()
	videoStore.$dispose()
})
</script>

<template>
	<AppLayout>
		<div v-if="!adStore.isShow"
		     class="absolute z-20 md:p-8 p-4 w-full flex font-bold items-center justify-between text-white">

			<img alt="" class="w-24" src="/images/3sex-logo-dark.png"/>

			<Link :href="route('live')"
			      class="text-white flex flex-col items-center justify-center space-y-0.5 hover:bg-white hover:bg-opacity-30 rounded-full w-12 h-12">
				<SignalIcon class="h-5"/>
				<span class="bg-white rounded text-[6pt] text-black px-0.5">Live</span>
			</Link>
		</div>

		<Swiper
			:direction="'vertical'"
			:grab-cursor="true"
			:modules="modules"
			:mousewheel="true"
			:slides-per-view="1"
			:space-between="5"
			:virtual="true"
			class="h-full w-full object-cover"
			@reachEnd="onReachEnd"
			@slideChange="onSlideChange"
			@slideNextTransitionEnd="onSwipeNext"
			@swiper="onSwiper">
			<SwiperSlide v-for="(slide, index) in slides" :key="index" :virtualIndex="index">
				<PostCard v-if="slide.resource==='post'" :post="slide" @video-ended="swipeNext"/>
				<Ad v-if="slide.resource==='ad'" :key="slide.id"/>
				<InstallPWASlide v-if="slide.resource==='pwa'"/>
			</SwiperSlide>
		</Swiper>
	</AppLayout>
</template>

