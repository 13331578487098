import {defineStore} from "pinia";
import {ref} from "vue";

export const useToastNotificationsStore = defineStore("toast-notifications", () => {
    const notifications = ref([]);

    function add(toast) {
        notifications.value.unshift({
            key: Symbol(),
            ...toast
        });
    }

    function remove(index) {
        notifications.value.splice(index, 1);
    }

    return {notifications, add, remove};
});
