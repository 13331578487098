<script setup>
import {onBeforeMount, onMounted, ref, watch} from "vue";
import Revive from "@/Pages/Ads/Partials/Revive.vue";
import DSC from "@/Pages/Ads/Partials/DSC.vue";
import Strip from "@/Pages/Ads/Partials/Strip.vue";
import IM from "@/Pages/Ads/Partials/IM.vue";
import {useAdStore} from "@/Stores/AdStore";
import {storeToRefs} from "pinia";
import Interstitial from "@/Pages/Ads/Partials/Interstitial.vue";
import {XMarkIcon} from "@heroicons/vue/24/outline";
import {useSwiper, useSwiperSlide} from "swiper/vue";
import {CircleProgressBar} from 'circle-progress.vue';
import {usePage} from "@inertiajs/vue3";


const progress = ref(0)
const progressInterval = ref(null)

defineProps({
	active: Boolean
})
const swiperSlide = useSwiperSlide();
const swiper = useSwiper();
const adStore = useAdStore();
const settings = ref(usePage().props.settings)
const {ad, counter, locked} = storeToRefs(adStore);

watch(() => swiperSlide.value, (slide) => {

	if (slide.isActive) {
		adStore.$patch({isShow: true})

		if (locked) {

			swiper.value.disable()
			setTimeout(() => {
				swiper.value.enable()
			}, settings.value.ads_pause)

			progressInterval.value = setInterval(() => {
				progress.value += 1
			}, 1000)
		} else {
			progress.value = counter.value / 1000
		}

	} else {
		adStore.$patch({isShow: false})
		progress.value = 0
		clearInterval(progressInterval.value)
	}

}, {immediate: true, deep: true})

onMounted(() => {
	adStore.getAd();
})

const close = () => {
	progress.value = 0
	clearInterval(progressInterval.value)
	window.masterOfPoppits.openPoppit(window.location.href)
}
</script>
<template>
	<div v-if="ad" class="relative w-full h-full exclude-poppit">

		<button class="absolute top-5 left-5 z-[999999]" @click="close">
			<CircleProgressBar :max="counter/1000" :value="progress"
			                   class="w-10 h-10 bg-white bg-opacity-80 rounded-full"
			                   size="48"
			                   strokeWidth="5px">
				<div class="flex justify-center items-center h-full w-full text-black">
                    <span v-if="locked && counter/1000 - progress > 0" class="text-2xl font-bold">{{
		                    counter / 1000 - progress
	                    }}</span>
					<XMarkIcon v-else class="h-6"/>
				</div>
			</CircleProgressBar>
			<span class="font-bold text-xs">Skip Ad</span>
		</button>

		<DSC v-if="ad.src === 'dsc'" :model="ad.data"/>
		<Revive v-if="ad.src === 'revive'"/>
		<Strip v-if="ad.src === 'stripchat'" :model="ad.data"/>
		<IM v-if="ad.src === 'im'"/>
		<Interstitial v-if="ad.src === 'interstitial'"/>
	</div>
</template>
