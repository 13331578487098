import {defineStore} from "pinia";
import {ref} from "vue";
import {usePage} from "@inertiajs/vue3";

export const useAdStore = defineStore("ad-store", () => {

	const settings = ref(usePage().props.settings)
	const counter = ref(settings.value.ads_pause);
	const locked = ref(false);
	const ads = ref(usePage().props.ads);
	const ad = ref(null);
	const adSwiper = ref(null);
	const isShow = ref(false);

	const weighted_random = () => {
		let i;

		let probabilities = ads.value.probabilities;

		let weights = [probabilities[0].weight];

		for (i = 1; i < probabilities.length; i++) {
			weights[i] = probabilities[i].weight + weights[i - 1];
		}

		let random = Math.random() * weights[weights.length - 1];

		for (i = 0; i < weights.length; i++) {
			if (weights[i] > random) {
				break;
			}
		}

		return probabilities[i].src;
	}

	const getAd = () => {

		let selected = weighted_random();

		switch (selected) {
			case 'dsc':
				window.axios.get(route('api.ad.dsc')).then(response => {
					ad.value = {
						src: 'dsc',
						data: response.data
					}
				})
				break;
			case 'revive':
				ad.value = {
					src: 'revive',
				}
				break;
			case 'sbv':
				ad.value = {
					src: 'sbv',
				}
				break;
			case 'im':
				ad.value = {
					src: 'im',
				}
				break;
			case 'interstitial':
				ad.value = {
					src: 'interstitial',
				}
				break;
			case 'stripchat':
				window.axios.get(route('api.ad.stripchat')).then(response => {
					ad.value = {
						src: 'stripchat',
						data: response.data
					}
				});
				break;
		}
	};

	const lockAd = () => {
		adSwiper.value.disable();
		locked.value = true;

		// setTimeout(() => {
		//     releaseAd();
		// }, settings.value.ads_pause)
	}

	const releaseAd = () => {
		adSwiper.value.enable();
		locked.value = false;
	}

	const setSwiper = (swiper) => {
		adSwiper.value = swiper;
	}

	return {
		ads,
		ad,
		counter,
		locked,
		isShow,
		setSwiper,
		getAd,
		lockAd,
		releaseAd
	}
});
