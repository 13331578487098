<template>
	<div class="flex h-screen w-screen relative flex-col">
		<div class="z-[1] flex h-full relative w-full max-w-2xl flex-col justify-center items-center p-4">

			<div class="absolute z-50 h-full w-20 right-0"></div>

			<iframe id='ab5ed8bd'
			        :src='"https://revive.videobaba.xyz/revive/www/delivery/afr.php?zoneid=314&amp;cb="+cb'
			        allow='autoplay'
			        height='100'
			        name='ab5ed8bd'
			        width='100%'>
			</iframe>

		</div>
	</div>
</template>

<script setup>
import {onMounted} from "vue";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";

const analyticsStore = useAnalyticsStore();

onMounted(() => {
	analyticsStore.sendAnalyticsEvent('ad_impression', {
		adType: 'IM'
	});
	analyticsStore.sendAnalyticsEvent('im_ad_impression');
});
</script>
