import {defineStore} from "pinia";
import {ref} from "vue";
import {useFullscreen} from "@vueuse/core";

export const useVideoStore = defineStore("video-store", () => {

    const video = ref(null);
    const fullscreen = useFullscreen(video.value?.player)
    const isFullscreenSupported = ref(fullscreen.isSupported)

    const setCurrentVideo = (current) => {
        video.value = current
    }
    const toggleModelAudio = () => {
        video.value.player.muted(!video.value.state.muted)
    }
    const toggleFullscreen = () => {
        fullscreen.toggle().then(() => {
            video.value.player.controls(fullscreen.isFullscreen)
        });
    }

    return {
        video,
        isFullscreenSupported,
        setCurrentVideo,
        toggleModelAudio,
        toggleFullscreen
    };
});
